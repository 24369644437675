.u-noPadding {
  padding: 0;
}

.u-block {
  display: block;
}

.u-marginTopSmall {
  margin-top: 5px;
}

.is-hidden {
  display: none;
}
