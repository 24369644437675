td {
  vertical-align: 'top';
}

.ant-table-tbody > tr > td {
  padding: 10px;
  vertical-align: 'top';
}
.ant-table-thead > tr > th {
  padding: 10px;
}

.createdRow {
  background-color: #bde7a6;
}

.updatedRow {
  background-color: #aeddf6;
}

.updatedSignifcantlyRow {
  background-color: #74caf9;
}

.removedRow {
  background-color: #f8e3e3;
}

.restoredRow {
  background-color: #f9e7a1;
}

.requiresReviewRow {
  background-color: #f8e3e3;
}

.ant-table-row-cell-break-word {
  vertical-align: top;
}

.ant-table-tbody > tr.ant-table-row-hover > td {
    background: none !important;
}

.ant-table-tbody > tr.ant-table-row-level-0:hover > td {
  background: none !important;
}

.ant-table-tbody > tr.ant-table-row-level-1:hover > td {
  background: none !important;
}

.ant-table-tbody > tr.ant-table-row-level-2:hover > td {
  background: none !important;
}

.ant-table-placeholder {
  margin-bottom: 16px;
}
