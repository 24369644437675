.paragraph-locations {
  line-height: 1.3em;
  margin-bottom: 0px;
}
.button {
  border: none;
  background-color: inherit;
  color: #1890ff;
  line-height: 1.3em;
  cursor: pointer;
  display: inline-block;
}
