table .ant-form-item {
  margin: 0;
}

table .new {
  background-color: #ffe6e6;
}

div .ant-row .ant-form-item {
  margin-bottom: 0;
}

.ant-form-item-label {
  line-height: 25px;
}

.ant-form-item-control {
  line-height: 30px;
}

table .ant-form-item-control {
  line-height: 20px;
}
